import styles from './styles.module.css';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TextField, Button, Grid, MenuItem } from '@material-ui/core';
import ShopSettingsService from '../../../services/ShopSettingsService';
import { GST_PREFERENCES, SUBSCRIPTION_TYPE } from '../../../const';
import { Alert } from '@material-ui/lab';
import { ShopContext } from '../../../Context/ShopContext';

export default function ShopSettings(props) {
  const { shop } = useContext(ShopContext);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [gstPreference, setGstPreference] = useState(null);
  // const [gekartSyncKey, setGekartSyncKey] = useState(' ');
  const [externalTokenKey, setExternalTokenKey] = useState(' ');
  const [rewardPointConfiguration, setRewardPointConfiguration] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const successDialog = async () => {
    setMessage('Succesfully updated!');
    setTimeout(() => {
      setMessage(null);
    }, 1500);
  };

  const failureDialog = async () => {
    setErrorMessage(
      'Oops! Something went wrong! Make sure you have entered all the details correctly!'
    );
    setTimeout(() => {
      setErrorMessage(null);
    }, 2000);
  };

  const loadGstPreference = useCallback(async () => {
    setLoadingIndicator(true);
    const gstPreference = await ShopSettingsService.getGstPreference();
    setLoadingIndicator(false);
    setGstPreference(gstPreference);
  }, [setLoadingIndicator, setGstPreference]);

  const updateGstBtnPressed = async () => {
    setLoadingIndicator(true);
    const data = { gst_preference: gstPreference };
    try {
      await ShopSettingsService.updateGstPreference(data);
      successDialog();
    } catch {
      failureDialog();
    }
  };

  const pointToRupeeBtnPressed = async () => {
    setLoadingIndicator(true);
    const data = { rupees: rewardPointConfiguration };
    try {
      await ShopSettingsService.updatePointsToRupee(data);
      successDialog();
    } catch {
      failureDialog();
    }
  };

  // const loadGeKartKey = useCallback(async () => {
  //   setLoadingIndicator(true);
  //   const data = await ShopSettingsService.getGeKartKey();
  //   const gekartSyncKey = data.gekart_access_token;
  //   setLoadingIndicator(false);
  //   setGekartSyncKey(gekartSyncKey);
  // }, [setLoadingIndicator, setGekartSyncKey]);

  // const updateGeKartKeyBtnPressed = async () => {
  //   setLoadingIndicator(true);
  //   const data = { key: gekartSyncKey };
  //   try {
  //     await ShopSettingsService.updateGeKartKey(data);
  //     successDialog();
  //   } catch {
  //     failureDialog();
  //   }
  // };

  const loadExternalTokenKey = useCallback(async () => {
    setLoadingIndicator(true);
    const data = await ShopSettingsService.getExternalTokenKey();
    const externalTokenKey = data.ext_access_token;
    setExternalTokenKey(externalTokenKey);
    setLoadingIndicator(false);
  }, [setLoadingIndicator, setExternalTokenKey]);

  const resetExtTknBtnPressed = async () => {
    setLoadingIndicator(true);
    try {
      const data = await ShopSettingsService.resetExternalTokenKey();
      const externalTokenKey = data.ext_access_token;
      setExternalTokenKey(externalTokenKey);
      setLoadingIndicator(false);
      successDialog();
    } catch (error) {
      failureDialog();
    }
  };

  const copyExternalToken = async () => {
    const copyText = document.getElementById('extTkn');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    copySuccess();
  };

  const copySuccess = async () => {
    setMessage('Copied external token');
    setTimeout(() => {
      setMessage(null);
    }, 2000);
  };

  useEffect(() => {
    loadGstPreference().then();
    // loadGeKartKey().then();
    loadExternalTokenKey().then();
  }, [loadGstPreference, loadExternalTokenKey]);

  const subscriptionType = shop?.subscription?.type;
  return (
    <div className={styles.contentLayout}>
      <div className={styles.settingsGrid}>
        <div style={{ height: '50px' }}>
          {message && <Alert severity="success">{message}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={styles.BillHeader}>
            <div className={styles.fieldHead}>
              <span className={styles.fieldTitle}>Tax Settings</span>
            </div>
            <div className={styles.fieldInput}>
              <TextField
                select
                name="gstPreference"
                size="small"
                variant="outlined"
                color="primary"
                fullWidth
                onChange={(event) => {
                  setGstPreference(event.target.value);
                }}
                value={gstPreference}
              >
                {Object.keys(GST_PREFERENCES).map((gst_pref) => (
                  <MenuItem key={gst_pref} value={gst_pref}>
                    {GST_PREFERENCES[gst_pref]}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className={styles.fieldButtons}>
              <Button
                variant="contained"
                color="secondary"
                style={{ backgroundColor: '#00a65a', width: '100%' }}
                onClick={updateGstBtnPressed}
                disabled={loadingIndicator}
              >
                Update Tax
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} className={styles.BillHeader}>
            <div className={styles.fieldHead}>
              <span className={styles.fieldTitle}>Reward point config</span>
            </div>
            <div className={styles.fieldInput}>
              <TextField
                label="How many currency is 1 reward point ?"
                name="rewardPointConfiguration"
                size="small"
                variant="outlined"
                color="primary"
                fullWidth
                onChange={(event) => {
                  setRewardPointConfiguration(event.target.value);
                }}
                value={rewardPointConfiguration}
              />
            </div>
            <div className={styles.fieldButtons}>
              <Button
                variant="contained"
                color="secondary"
                style={{ backgroundColor: '#00a65a', width: '100%' }}
                onClick={pointToRupeeBtnPressed}
                disabled={loadingIndicator}
              >
                Points to Currency
              </Button>
            </div>
          </Grid>
          {/* <Grid item xs={12} className={styles.BillHeader}>
            <div className={styles.fieldHead}>
              <span className={styles.fieldTitle}>Cart Sync Key</span>
            </div>
            <div className={styles.fieldInput}>
              <TextField
                name="gekartSyncKey"
                size="small"
                variant="outlined"
                color="primary"
                fullWidth
                value={gekartSyncKey}
              />
            </div>
            <div className={styles.fieldButtons}>
              <Button
                variant="contained"
                color="secondary"
                style={{ backgroundColor: '#00a65a', width: '100%' }}
                onClick={updateGeKartKeyBtnPressed}
                disabled={loadingIndicator}
              >
                Save
              </Button>
            </div>
          </Grid> */}
          {subscriptionType === SUBSCRIPTION_TYPE.PREMIUM && (
            <Grid item xs={12} className={styles.BillHeader}>
              <div className={styles.fieldHead}>
                <span className={styles.fieldTitle}>External Token Key</span>
              </div>
              <div className={styles.fieldInput}>
                <TextField
                  id="extTkn"
                  name="externalTokenKey"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  value={externalTokenKey}
                />
              </div>
              <div className={styles.fieldButtons}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      style={{ width: '100%' }}
                      onClick={copyExternalToken}
                      disabled={loadingIndicator}
                    >
                      Copy
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ backgroundColor: '#00a65a', width: '100%' }}
                      onClick={resetExtTknBtnPressed}
                      disabled={loadingIndicator}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}
