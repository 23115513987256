import styles from './styles.module.css';
import { useState, useEffect, useContext } from 'react';
import { Avatar } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { STORAGE_KEYS, ROUTES } from '../../../const';
import { Link } from 'react-router-dom';
import ShopService from '../../../services/ShopService';
import AccountSwitchDialog from '../../popups/AccountSwitchDialog';
import AddShopDialog from '../../popups/AddShopDialog';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import ShopsHelper from '../../../helpers/ShopsHelper';
import SubscriptionEndingSoonAlert from '../SubscriptionEndingSoonAlert';

export default function TitleBar() {
  const { user } = useContext(UserContext);
  const { shop, setShop } = useContext(ShopContext);
  const [shops, setShops] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEI, setAnchorEI] = useState(null);
  const [addShopTab, setAddShopTab] = useState(false);
  const date = DateTimeHelpers.convertDateToDMY(new Date());

  useEffect(() => {
    async function setSelectedShop() {
      const shops = await ShopService.getShops();
      setShops(shops);

      const selectedShopIdStr = localStorage.getItem(STORAGE_KEYS.SHOP_ID);
      const selectedShopId = parseInt(selectedShopIdStr);
      const shopFromSelectedId = isNaN(selectedShopId)
        ? shops[0]
        : shops.find((shop) => shop.id === selectedShopId);
      const selectedShop = shopFromSelectedId ||
        shops[0] || { id: -1, shop_name: 'unknown' };
      ShopsHelper.saveSelectedShopDetails(selectedShop);
      setShop(selectedShop);
    }
    setSelectedShop();
  }, [setShop]);

  const handleAccountView = (e) => {
    setAnchorEI(e.currentTarget);
    return setIsOpen(!isOpen);
  };
  const updateShops = async () => {
    const res = await ShopService.getShops();
    setShops(res);
  };
  const toggleAddShopDialog = () => setAddShopTab(!addShopTab);

  return (
    <div className={styles.titleBarLayout}>
      <div className={`${styles.flexChildCenter} ${styles.appHeading}`}>
        <div className={styles.logoWrapper}>
          <Link
            to={ROUTES.DASHBOARD}
            style={{ textDecorationColor: 'transparent' }}
          >
            <span className={styles.logoTextColor}>SANOFT</span>
            <span className={styles.logoText}>POS</span>
          </Link>
        </div>
      </div>
      <div className={styles.profileWrapper}>
        <div
          className={styles.flexChildCenter}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div>
            <div className={styles.profileTextMain}>
              Welcome, {user && user.first_name} {user && user.last_name},
            </div>

            <div className={styles.profileTextSub}>{date}</div>
          </div>
        </div>
        <div
          className={styles.flexChildCenter}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <SubscriptionEndingSoonAlert shop={shop} />
        </div>
        {addShopTab && (
          <AddShopDialog
            toggleAddTab={toggleAddShopDialog}
            updateShops={updateShops}
          />
        )}
        <div className={styles.actionButtons}>
          <div className={styles.profileSec} onClick={handleAccountView}>
            <span className={`${styles.iconHolder} ${styles.navIcon}`}>
              <Avatar style={{ backgroundColor: '#3f8dce' }}>
                <PersonIcon style={{ color: '#fff', fontSize: '25px' }} />
              </Avatar>
            </span>
            <AccountSwitchDialog
              accounts={shops}
              toggleAddShopDialog={toggleAddShopDialog}
              anchorEI={anchorEI}
              isOpen={isOpen}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
