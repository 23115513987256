export const BACKEND_URL = 'https://sanoft-pos-legacy.uc.r.appspot.com/api';
// export const BACKEND_URL = 'https://yscloud.in/backend/api';
// export const BACKEND_URL = 'http://127.0.0.1:8000/api';

export const STORAGE_KEYS = {
  TOKEN: 'x-auth-token',
  USER_ID: 'x-auth-user-id',
  SHOP_ID: 'x-selected-shop-id',
  FIRST_NAME: 'x-first-name',
  LAST_NAME: 'x-last-name',
  USERNAME: 'x-username',
  BILL_PRINTER_IP: 'x-bill-printer-ip',
  USER_TYPE: 'x-user-type',
  SHOP_NAME: 'x-shop-name',
  ACTIVE_TAB: 'x-active-tab',
  DONT_SHOW_OTHER_SHOPS_ON_ITEM_EDIT: 'x-dont-show-other-shops-on-item-edit',
};

export const HEADERS = {
  AUTH: 'Authorization',
  SHOP_ID: 'shop-id',
};

export const GST_PREFERENCES = {
  0: 'No Tax',
  1: 'Exclude Tax',
  2: 'Include Tax',
};

export const USER_PERMISSIONS = {
  DENY: 'deny',
  READ: 'read',
  WRITE: 'write',
};

export const SUBSCRIPTION_TYPE = {
  BASIC: 'basic',
  STANDARD: 'standard',
  PREMIUM: 'premium',
};

export const ROUTES = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  SIGNUP: '/signup',
  ANY: '/',
  CONSOLE: '/console',

  DASHBOARD: '/console/dashboard',
  ITEMS: '/console/items',
  CATEGORIES: '/console/categories',
  DEVICES: '/console/devices',
  CUSTOMERS: '/console/customers',
  PURCHASE_HISTORY: '/console/purchase-history',
  NEW_PURCHASE: '/console/new-purchase',
  EDIT_PURCHASE: '/console/edit-purchase/:id',
  DAMAGE_HISTORY: '/console/damage-history',
  NEW_DAMAGE: '/console/new-damage',
  ITEM_SUMMARY: '/console/item-summary',
  ITEMISED_BILL: '/console/itemised-bill',
  BILL_SUMMARY: '/console/bill-summary',
  STOCK_REPORT: '/console/stock-report',
  SETTINGS: '/console/settings',
  SUBSCRIPTION_PLANS: '/subscription-plans',
  PERMISSION_DETAIL: '/settings/shop-users/:id',
  PAYMENT_CALLBACK: '/subscription-plans/payment-callback',
  PAGE_404: '/page-404',
  EXPENSES: '/console/expenses',
};
