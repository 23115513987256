import React, { useState } from 'react';
import styles from './styles.module.css';
import { TextField, Grid, Box, Paper, Button } from '@material-ui/core';
import Loader from '../../utils/Loader';
import { ROUTES } from '../../../const';
import AuthService from '../../../services/AuthService';
import { useHistory, Link } from 'react-router-dom';

export default function NewAccount(props) {
  const [shopname, setShopname] = useState('');
  const [username, setUsername] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();

  const signupBtnPressed = async (e) => {
    const data = {
      shop_name: shopname,
      username: username,
      first_name: firstname,
      last_name: lastname,
      password: password,
      mobile: mobile,
      currency: 'Rs',
    };
    if (
      !shopname ||
      !username ||
      !firstname ||
      !lastname ||
      !password ||
      !mobile
    ) {
      setErrorMessage(
        'Required field should not be empty.\n Fill the form correctly.'
      );
      setTimeout(() => {
        setErrorMessage(false);
      }, 4000);
      return;
    }
    setLoadingIndicator(true);
    try {
      await AuthService.createAccount(data);
      history.push(ROUTES.DASHBOARD);
    } catch (err) {
      setErrorMessage('Failed to login !');
    }
    setLoadingIndicator(false);
  };

  return (
    <Grid className={styles.mainDiv}>
      <Box container className={styles.outerBoxFrameWithoutAd}>
        <Grid style={{ boxShadow: 3 }} item xs={12} sm={12}>
          <Grid className={styles.formBoxFrame}>
            <h3 className={styles.titleText}>Create a new account</h3>
            <div className={styles.textMarg}>
              <TextField
                name="shopname"
                size="small"
                label="Shop Name"
                type="text"
                required
                autoComplete="current-password"
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setShopname(event.target.value);
                }}
              />
            </div>
            <div className={styles.textMarg}>
              <TextField
                name="username"
                size="small"
                id="outlined-password-input"
                label="Username"
                type="text"
                required
                autoComplete="current-password"
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
            </div>
            <div className={styles.textMarg}>
              <TextField
                name="firstname"
                size="small"
                id="outlined-password-input"
                label="First Name"
                type="text"
                required
                autoComplete="current-password"
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setFirstname(event.target.value);
                }}
              />
            </div>
            <div className={styles.textMarg}>
              <TextField
                name="lastname"
                size="small"
                id="outlined-password-input"
                label="Last Name"
                type="text"
                required
                autoComplete="current-password"
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setLastname(event.target.value);
                }}
              />
            </div>
            <div className={styles.textMarg}>
              <TextField
                name="password"
                size="small"
                id="outlined-password-input"
                label="Password"
                type="password"
                required
                autoComplete="current-password"
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </div>
            <div className={styles.textMarg}>
              <TextField
                name="mobilenumber"
                size="small"
                id="outlined-password-input"
                label="Mobile Number"
                type="number"
                required
                autoComplete="current-password"
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setMobile(event.target.value);
                }}
              />
            </div>
            {errorMessage && (
              <Paper className={styles.errorMsg}>
                <span>{errorMessage}</span>
              </Paper>
            )}
            <div className={styles.loginLoader}>
              {loadingIndicator && <Loader />}
            </div>
            <Grid className={styles.textCenter}>
              <Button
                style={{ backgroundColor: '#3f8dce' }}
                variant="contained"
                color="secondary"
                fullWidth
                onClick={signupBtnPressed}
                disabled={loadingIndicator}
              >
                Signup
              </Button>
            </Grid>
            <Grid className={styles.forgotPasswordWrapper}>
              <Link to="/login" className={styles.forgotPassword}>
                Goto login?
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
