import {
  Popper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Divider,
  Button,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import { useState, useEffect, useContext } from 'react';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
// import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import PersonIcon from '@material-ui/icons/Person';
import { ROUTES, STORAGE_KEYS } from '../../const';
import AuthService from '../../services/AuthService';
import ShopSettingsService from '../../services/ShopSettingsService';
import { ShopContext } from '../../Context/ShopContext';

export default function AccountSwitchDialog(props) {
  const { accounts, anchorEI, isOpen } = props;
  const { shop } = useContext(ShopContext);
  const history = useHistory();
  const shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID);
  const [shopProfile, setShopProfile] = useState();

  useEffect(() => {
    const loadShopProfile = async () => {
      const shopProfile = await ShopSettingsService.loadShopProfile();
      setShopProfile(shopProfile);
    };
    loadShopProfile();
  }, [shop]);

  const handleSwitchAccount = (account) => {
    localStorage.removeItem(STORAGE_KEYS.SHOP_NAME);
    localStorage.setItem(STORAGE_KEYS.SHOP_ID, account.id);
    window.location.reload();
    history.push(ROUTES.DASHBOARD);
  };
  const logoutBtnPressed = async (e) => {
    await AuthService.logout();
    window.location.reload();
  };
  return (
    <Popper
      id="spring-popper"
      open={isOpen}
      anchorEl={anchorEI}
      placement="bottom"
      style={{ zIndex: 3, width: 300 }}
    >
      <Paper elevation={15}>
        <List>
          {shopId && shopProfile && (
            <>
              <ListItem button key={shopId} className={styles.selectedProfile}>
                <ListItemAvatar key={shopId}>
                  <PersonIcon />
                </ListItemAvatar>
                <ListItemText primary={shopProfile.shop_name} />
              </ListItem>
              <Divider component="li" />
              {accounts &&
                accounts.map((account) => {
                  return (
                    <div key={account.id}>
                      {account.id.toString() !== shopId && (
                        <>
                          <ListItem
                            button
                            onClick={() => handleSwitchAccount(account)}
                            key={account.shop_name}
                          >
                            <ListItemAvatar key={account.id}>
                              <PersonIcon />
                            </ListItemAvatar>
                            <ListItemText primary={account.shop_name} />
                          </ListItem>
                          <Divider component="li" />
                        </>
                      )}
                    </div>
                  );
                })}
            </>
          )}
          {/* <ListItem>
            <Button
              variant="contained"
              size="small"
              fullWidth
              className={styles.actionBtn}
              style={{
                backgroundColor: '#605ca8',
                padding: '4px',
                color: '#fff',
              }}
              onClick={toggleAddShopDialog}
            >
              <AddBusinessIcon
                fontSize="small"
                className={styles.addShopIcon}
              />
              New Shop
            </Button>
          </ListItem> */}
          <ListItem>
            <Link
              to={ROUTES.LOGIN}
              style={{ textDecorationColor: 'transparent', width: '100%' }}
            >
              <Button
                variant="outlined"
                color="primary"
                size="small"
                fullWidth
                className={styles.actionBtn}
                onClick={logoutBtnPressed}
              >
                <PowerSettingsNewIcon
                  fontSize="small"
                  className={styles.actionBtnIcon}
                />
                Logout
              </Button>
            </Link>
          </ListItem>
        </List>
      </Paper>
    </Popper>
  );
}
