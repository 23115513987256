import React, { useState, useEffect, useContext } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { TextField, MenuItem, Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import ItemisedBillReportService from '../../../services/ItemisedBillReportService';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { saveAs } from 'file-saver';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import ItemService from '../../../services/ItemService';
import DeviceService from '../../../services/DeviceService';
import ItemSummaryReportService from '../../../services/ItemSummaryReportService';
import Info from '../../utils/Alert/Info';
import Loader from '../../utils/Loading';
import setDelay from '../../../helpers/LoadingDelay';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import Success from '../../utils/Alert/Success';
import Error from '../../utils/Alert/Error';
import { SUBSCRIPTION_TYPE } from '../../../const';
import { ShopContext } from '../../../Context/ShopContext';

function ItemisedBillSummaryReport() {
  const { shop } = useContext(ShopContext);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState(new Date());
  const [filterToDate, setFilterToDate] = useState(new Date());
  const [machineName, setMachineName] = useState('all');
  const [category, setCategory] = useState('all');
  const [currency, setCurrency] = useState('');
  const [gst, setGst] = useState('all');
  const [item, setItem] = useState('all');
  const [sorting, setSorting] = useState('desc');
  const [itemNames, setItemNames] = useState('');
  const [items, setItems] = useState();
  const [searchText, setSearchText] = useState('');
  const [categories, setCategories] = useState('');
  const [vats, setVats] = useState('');
  const [machineNames, setMachineNames] = useState('');
  const [filteredItems, setFilteredItems] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();

  useEffect(() => {
    getCategories().then();
    getMachineName().then();
    getVat().then();
    getItemNames().then();
    loadItemisedBillReports(filterFromDate, filterToDate).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCategories = async () => {
    const res = await ItemService.getItemCategories();
    setCategories(res);
  };
  const getItemNames = async () => {
    const res = await ItemService.getItemNames();
    setItemNames(res);
  };
  const getMachineName = async () => {
    const res = await DeviceService.getDevices();
    setMachineNames(res);
  };
  const getVat = async () => {
    const res = await ItemSummaryReportService.getVat();
    setVats(res);
  };
  const toExcelBtnPressed = async (e) => {
    try {
      const data = {
        date: DateTimeHelpers.convertDateToDMY(filterFromDate),
        to_date: DateTimeHelpers.convertDateToDMY(filterToDate),
        target: 'excel',
      };
      const res = await ItemisedBillReportService.getItemisedBillExcel(data);
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `itemised-bill-summary-${data.date}-${data.to_date}.xlsx`);
      AlertSetter(setSuccessMsg, 'Exported to excel successfully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
  };

  const toCsvBtnPressed = async (e) => {
    try {
      const data = {
        date: DateTimeHelpers.convertDateToDMY(filterFromDate),
        to_date: DateTimeHelpers.convertDateToDMY(filterToDate),
        target: 'csv',
      };
      const res = await ItemisedBillReportService.getItemisedBillCsv(data);
      const blob = new Blob([res.data], {
        type: 'text/csv',
      });
      saveAs(blob, `itemised-bill-summary-${data.date}-${data.to_date}.csv`);
      AlertSetter(setSuccessMsg, 'Exported to CSV successfully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
  };

  const loadItemisedBillReports = async (
    from,
    to,
    sort = sorting,
    catg = category,
    mac = machineName,
    itm = item,
    vat = gst
  ) => {
    setLoadingIndicator(true);
    const data = {
      date: DateTimeHelpers.convertDateToDMY(from),
      to_date: DateTimeHelpers.convertDateToDMY(to),
      sort_bill_num: sort,
      category_id: catg === 'all' ? '' : catg,
      filter_mac: mac === 'all' ? '' : [mac],
      filter_name: itm === 'all' ? '' : [itm],
      filter_vat: vat === 'all' ? '' : [vat],
    };
    const resp = await ItemisedBillReportService.getItemisedBillReport(data);
    const itemData = [
      ...resp.report,
      {
        bill_no: '',
        name: '',
        date: '',
        machine_no: '',
        plu: '',
        price: '',
        time: '',
        vat: '',
        vat_amount: '',
        qty: '',
        purchase_rate: '',
        purchase_tax: '',
        purchase_expense: 'Total',
        profit: '',
        total: Number(resp.sales_total),
      },
    ];
    setFilteredItems(itemData);
    setItems(itemData);
    setDelay(setLoadingIndicator);
    setCurrency(resp.currency);
  };
  const handleSearch = (value) => {
    setSearchText(value);
    setFilteredItems(
      items.filter(
        (item) =>
          item.bill_no.toString().includes(value.toLowerCase()) ||
          item.name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const subscriptionType = shop?.subscription?.type || '';
  const headerData = [
    {
      label: 'Bill No',
      id: 'bill_no',
      type: 'text',
    },
    {
      label: 'Counter',
      id: 'machine_no',
      type: 'callback',
      viewRender: (obj) =>
        obj.machine_name || obj.machine_no === 998 ? 'POS' : obj.machine_no,
    },
    {
      label: 'PLU',
      id: 'plu',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Date',
      id: 'date',
      type: 'text',
    },
    {
      label: 'Time',
      id: 'time',
      type: 'text',
    },
    {
      label: 'Unit Price',
      id: 'price',
      type: 'floatAmount',
    },
    {
      label: 'Tax(%)',
      id: 'vat',
      type: 'text',
    },
    {
      label: 'Tax Amount',
      id: 'vat_amount',
      type: 'floatAmount',
    },
    {
      label: 'Sold Qty',
      id: 'qty',
      type: 'callback',
      viewRender: (item) => {
        if (!item.qty || isNaN(item.qty)) return;
        return `${item.qty.toFixed(3)} ${item.unit || ''}`;
      },
    },
  ];
  if (subscriptionType === SUBSCRIPTION_TYPE.PREMIUM) {
    headerData.push({
      label: 'Purchase Rate',
      id: 'purchase_rate',
      type: 'callback',
      viewRender: (item) => {
        if (item.purchase_rate === '' || isNaN(item.purchase_rate)) {
          return item.purchase_rate;
        }
        return `${currency || ''} ${item.purchase_rate.toFixed(2)}`;
      },
    });
    headerData.push({
      label: 'Purchase Tax',
      id: 'purchase_tax',
      type: 'callback',
      viewRender: (item) => {
        if (item.purchase_tax === '' || isNaN(item.purchase_tax)) {
          return item.purchase_tax;
        }
        return `${currency || ''} ${item.purchase_tax.toFixed(2)}`;
      },
    });
    headerData.push({
      label: 'Expense',
      id: 'purchase_expense',
      type: 'callback',
      viewRender: (item) => {
        if (item.purchase_expense === '' || isNaN(item.purchase_expense)) {
          return item.purchase_expense;
        }
        return `${currency || ''} ${item.purchase_expense.toFixed(2)}`;
      },
    });
    headerData.push({
      label: 'Profit*',
      id: 'profit',
      type: 'callback',
      viewRender: (item) => {
        if (item.profit === '' || isNaN(item.profit)) {
          return item.profit;
        }
        return `${currency || ''} ${item.profit.toFixed(2)}`;
      },
    });
  }
  headerData.push({
    label: 'Total Price',
    id: 'total',
    type: 'callback',
    viewRender: (item) => {
      return `${currency || ''} ${item.total.toFixed(2)}`;
    },
  });

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <span className={styles.title}>
          {' '}
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
        <div style={{ justifyContent: 'flex-end' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Export As</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#d81b60', marginRight: 5 }}
            onClick={toExcelBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            Excel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={toCsvBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            CSV
          </Button>
        </div>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>
              Itemised Bill Summary Report for{'  '}
              {DateTimeHelpers.convertDateToDMY(filterFromDate)}
              {'  '}to{'  '}
              {DateTimeHelpers.convertDateToDMY(filterToDate)}
            </h2>
          </div>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="search items"
              />
              <SearchIcon className={styles.searchIcon} />
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>From</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  variant="outlined"
                  format="dd/MM/yyyy"
                  id="date-picker-from"
                  className={styles.dateBox}
                  value={filterFromDate}
                  onChange={(date) => {
                    setFilterFromDate(date);
                    loadItemisedBillReports(date, filterToDate);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>To</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  variant="outlined"
                  format="dd/MM/yyyy"
                  id="date-picker-to"
                  className={styles.dateBox}
                  value={filterToDate}
                  onChange={(date) => {
                    setFilterToDate(date);
                    loadItemisedBillReports(filterFromDate, date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Counter Name</label>
            </div>
            <div>
              <TextField
                select
                name="machineName"
                size="small"
                variant="outlined"
                defaultValue={machineName}
                color="primary"
                className={styles.textfeild}
                onChange={(event) => {
                  setMachineName(event.target.value);
                  loadItemisedBillReports(
                    filterFromDate,
                    filterToDate,
                    sorting,
                    category,
                    event.target.value
                  );
                }}
              >
                {machineNames &&
                  machineNames.map((machine) => (
                    <MenuItem
                      value={machine.machine_number}
                      key={machine.machine_number}
                    >
                      {machine.machine_name || machine.machine_number}
                    </MenuItem>
                  ))}
                <MenuItem value="all">All</MenuItem>
              </TextField>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Tax</label>
            </div>
            <div>
              <TextField
                select
                name="category"
                size="small"
                variant="outlined"
                defaultValue={gst}
                color="primary"
                className={styles.textfeild}
                onChange={(event) => {
                  setGst(event.target.value);
                  loadItemisedBillReports(
                    filterFromDate,
                    filterToDate,
                    sorting,
                    category,
                    machineName,
                    item,
                    event.target.value
                  );
                }}
              >
                {vats &&
                  vats.map((vat) => (
                    <MenuItem value={vat} key={vat}>
                      {vat}
                    </MenuItem>
                  ))}
                <MenuItem value="all">All</MenuItem>
              </TextField>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Category</label>
            </div>
            <div>
              <TextField
                select
                name="gst"
                size="small"
                variant="outlined"
                defaultValue={category}
                color="primary"
                className={styles.textfeild}
                onChange={(event) => {
                  setCategory(event.target.value);
                  loadItemisedBillReports(
                    filterFromDate,
                    filterToDate,
                    sorting,
                    event.target.value
                  );
                }}
              >
                {categories &&
                  categories.map((category) => (
                    <MenuItem value={category.id} key={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                <MenuItem value="all">All</MenuItem>
              </TextField>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Item</label>
            </div>
            <div>
              <TextField
                select
                name="gst"
                size="small"
                variant="outlined"
                defaultValue={item}
                color="primary"
                className={styles.textfeild}
                onChange={(event) => {
                  setItem(event.target.value);
                  loadItemisedBillReports(
                    filterFromDate,
                    filterToDate,
                    sorting,
                    category,
                    machineName,
                    event.target.value
                  );
                }}
              >
                {itemNames &&
                  itemNames.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                <MenuItem value="all">All</MenuItem>
              </TextField>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Bill No Sort</label>
            </div>
            <div>
              <TextField
                select
                name="billNo"
                size="small"
                variant="outlined"
                color="primary"
                style={{ width: 135 }}
                defaultValue={sorting}
                onChange={(event) => {
                  setSorting(event.target.value);
                  loadItemisedBillReports(
                    filterFromDate,
                    filterToDate,
                    event.target.value
                  );
                }}
              >
                <MenuItem value={'desc'}>Descending</MenuItem>
                <MenuItem value={'asc'}>Ascending</MenuItem>
              </TextField>
            </div>
          </div>
        </div>
      </div>
      {errorMsg && (
        <div className={styles.marginTop}>
          <Error title={errorMsg} />
        </div>
      )}
      {successMsg && (
        <div className={styles.marginTop}>
          <Success title={successMsg} />
        </div>
      )}

      {items &&
        (filteredItems && filteredItems.length - 1 ? (
          <DataTable
            columns={headerData}
            rows={filteredItems ? filteredItems : items}
            rowKey="id"
          />
        ) : (
          <Info
            title={'You have no report to show for this date'}
            content={
              'Reports are generated based on the sales. Please verify your inputs to the generate report form.'
            }
          />
        ))}
    </div>
  );
}

export default withConsoleBase(ItemisedBillSummaryReport);
