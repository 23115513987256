function getMachineString(machine) {
  return machine.machine_name || machine.machine_number === '998'
    ? 'POS'
    : machine.machine_number;
}

const MachineHelper = {
  getMachineString,
};

export default MachineHelper;
