import React, { useState, useEffect, useContext } from 'react';
import { Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable/';
import NewCustomerDialog from '../../popups/NewCustomerDialog';
import EditCustomerDialog from '../../popups/EditCustomerDialog';
import CustomerService from '../../../services/CustomerService';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Loader from '../../utils/Loading';
import Delete from '../../utils/Alert/ConfirmPopup';
import setDelay from '../../../helpers/LoadingDelay';
import SearchIcon from '@material-ui/icons/Search';
import Info from '../../utils/Alert/Info';
import ListDialog from '../../popups/ListDialog';
import Error from '../../utils/Alert/Error';
import Success from '../../utils/Alert/Success';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import { ShopContext } from '../../../Context/ShopContext';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
import { UserContext } from '../../../Context/UserContext';
import { USER_PERMISSIONS, SUBSCRIPTION_TYPE } from '../../../const';

function Customers() {
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);

  const [customers, setCustomers] = useState();
  const [customersFiltered, setCustomersFiltered] = useState('');
  const [searchText, setSearchText] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [addTab, setAddTab] = useState('');
  const [editData, setEditData] = useState('');
  const [deleteData, setDeleteData] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [billData, setBillData] = useState('');
  const [successMsg, setSuccessMsg] = useState();

  const subscriptionType = shop?.subscription?.type;
  const userHasCustomersWritePermission =
    user?.shop_permissions?.customers_permission === USER_PERMISSIONS.WRITE;

  const handleDeleteClick = (data) => setDeleteData(data);

  const handleDltCustomer = async () => {
    setLoadingIndicator(true);
    try {
      await CustomerService.deleteCustomer(deleteData.id);
      getCustomers().then();
      AlertSetter(setSuccessMsg, 'Customer deleted successufully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
    setDelay(setLoadingIndicator);
  };

  const toggleEditTab = (rowData = '') => {
    editData ? setEditData('') : setEditData(rowData);
  };

  const toggleAddTab = (move = '') => {
    if (move === 'save') getCustomers();
    addTab ? setAddTab('') : setAddTab('open');
  };
  const handleSearch = (value) => {
    setSearchText(value);
    const filterResponse = customers
      ? customers.filter(
          (customer) =>
            customer.name.toLowerCase().includes(value.toLowerCase()) ||
            customer.location.toLowerCase().includes(value.toLowerCase()) ||
            customer.phone.toString().includes(value)
        )
      : [];
    setCustomersFiltered(filterResponse);
  };
  const getCustomers = async () => {
    setLoadingIndicator(true);
    const res = await CustomerService.getCustomers();
    setCustomersFiltered(res);
    setCustomers(res);
    setDelay(setLoadingIndicator);
  };
  const toggleDetailsTab = async (data) => {
    if (data === '') return setBillData('');
    setLoadingIndicator(true);
    const res = await CustomerService.getLastFourBill(data.id);
    setBillData(res);
    setDelay(setLoadingIndicator);
  };
  const handleDltCustomerPurchase = async (purchase) => {
    setLoadingIndicator(true);
    try {
      const data = {
        bill_no: purchase.fid,
        machine_no: purchase.machine_no,
      };
      await CustomerService.deleteCustomerPurchase(data);
      toggleDetailsTab('');
      AlertSetter(setSuccessMsg, 'Sales unassigned successufully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
    setDelay(setLoadingIndicator);
  };
  const headerData = [
    {
      label: 'ID',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Phone',
      id: 'phone',
      type: 'text',
    },
    {
      label: 'Place',
      id: 'location',
      type: 'text',
    },
    {
      label: 'Active',
      id: 'is_active',
      type: 'callback',
      viewRender: (obj) => {
        return obj.is_active === true ? 'Yes' : 'No';
      },
    },
    {
      label: 'Points',
      id: 'points',
      type: 'text',
    },
    {
      label: 'Purchase Details',
      id: 'purchaseDetails',
      type: 'button',
      title: 'Details',
      clickHandler: toggleDetailsTab,
    },
  ];
  if (userHasCustomersWritePermission) {
    headerData.push({
      label: 'Edit',
      id: 'updateItems',
      type: 'button',
      title: 'Edit',
      clickHandler: toggleEditTab,
    });
    headerData.push({
      label: 'Delete',
      id: 'deleteBtn',
      type: 'button',
      title: 'Delete',
      clickHandler: handleDeleteClick,
    });
  }
  const billHeader = [
    {
      label: 'Bill ID',
      id: 'sid',
      type: 'text',
    },
    {
      label: 'Bill date',
      id: 'bill_date',
      type: 'text',
    },
    {
      label: 'Counter',
      id: 'machine_no',
      type: 'text',
    },
    {
      label: 'Total QTY',
      id: 'total_qty',
      type: 'floatQuantity',
    },
    {
      label: 'Total Amount',
      id: 'total',
      type: 'floatAmount',
    },
  ];
  if (userHasCustomersWritePermission) {
    billHeader.push({
      label: 'Delete Sale',
      id: 'deleteBtn',
      type: 'button',
      title: 'Delete',
      clickHandler: handleDltCustomerPurchase,
    });
  }
  useEffect(() => {
    if (subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC) {
      getCustomers().then();
    }
  }, [editData, addTab, subscriptionType]);

  useEffect(() => {
    handleSearch(searchText);
    //eslint-disable-next-line
  }, [customers]);

  return (
    <div className={styles.contentWrapper}>
      <ConfirmPopup
        data={deleteData}
        handleClose={handleDeleteClick}
        handleOkay={handleDltCustomer}
        alertTitle={'Confirm delete'}
        successBtnName={'delete'}
        alertContent={
          "Deleted customer can't be restored, do you want to delete the customer?"
        }
      />
      <Loader isOpen={loadingIndicator} />
      <Delete
        deleteData={deleteData}
        handleClose={handleDeleteClick}
        handleDeletion={handleDltCustomer}
      />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Customer<span className={styles.menuTitle}>Management</span>
        </h2>
      </div>
      {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC && (
        <RestrictionInfo
          title={'Feature not available '}
          content={
            'To get customer support options upgrade subscription to premium.'
          }
        />
      )}
      {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
        <div className={styles.changeable}>
          <div className={styles.filterSec}>
            <div className={styles.headTitle}>
              <h2 className={styles.subTitle}>Customers</h2>
            </div>

            <div className={styles.filerInputSec}>
              {userHasCustomersWritePermission && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ backgroundColor: '#ff851b' }}
                  className={styles.actionBtn}
                  onClick={toggleAddTab}
                >
                  <NoteAddIcon className={styles.actionBtnIcon} />
                  New Customer
                </Button>
              )}
              <div className={styles.searchSec}>
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  className={styles.searchInput}
                  placeholder="search items"
                />
                <SearchIcon className={styles.searchIcon} />
              </div>
            </div>
          </div>
        </div>
      )}
      {errorMsg && (
        <div className={styles.marginTop}>
          <Error title={errorMsg} />
        </div>
      )}
      {successMsg && (
        <div className={styles.marginTop}>
          <Success title={successMsg} />
        </div>
      )}

      {subscriptionType &&
        subscriptionType !== SUBSCRIPTION_TYPE.BASIC &&
        customers &&
        (customersFiltered && customersFiltered.length ? (
          <DataTable
            columns={headerData}
            rows={customersFiltered ? customersFiltered : customers}
            toggleEditTab={toggleEditTab}
          />
        ) : (
          <Info
            title={'No customers to list'}
            content={
              'You have no customers to list with current filter configuration. Please clear the filters or create a customer'
            }
          />
        ))}
      {editData && (
        <EditCustomerDialog
          toggleEditTab={toggleEditTab}
          editData={editData}
          setSuccessMsg={setSuccessMsg}
        />
      )}
      {addTab && (
        <NewCustomerDialog
          toggleAddTab={toggleAddTab}
          setErrorMsg={setErrorMsg}
          setSuccessMsg={setSuccessMsg}
        />
      )}
      {billData && (
        <ListDialog
          toggleItemsTab={toggleDetailsTab}
          rowData={billData}
          tableHeader={billHeader}
          title={'Last five purchases'}
          emptyDataTitle="You don't have purchased anything yet"
          emptyDataContent={'This customer have an empty purchase history'}
        />
      )}
    </div>
  );
}

export default withConsoleBase(Customers);
