import styles from './styles.module.css';
import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Grid, MenuItem } from '@material-ui/core';
import BillFormatService from '../../../services/BillFormatService';
import Loader from '../../utils/Loading';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import Error from '../../utils/Alert/Error';
import Success from '../../utils/Alert/Success';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import MachineHelper from '../../../helpers/MachineHelper';
import { USER_PERMISSIONS, SUBSCRIPTION_TYPE } from '../../../const';

export default function BillFormat() {
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const subscriptionType = shop?.subscription?.type;
  const [successMsg, setSuccessMsg] = useState();
  const [errMsg, setErrMsg] = useState();
  const [billFormat, setBillFormat] = useState({
    machines: [],
    shopName: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    telNo: '',
    gstNumber: '',
    footer1: '',
    footer2: '',
    selectedMachine: null,
  });

  const userHasShopWritePermission =
    user?.shop_permissions?.shop_permission === USER_PERMISSIONS.WRITE;

  const handleBillFormatChange = (e) =>
    setBillFormat({
      ...billFormat,
      [e.target.name]: e.target.value,
    });
  const resetErrMsg = () => {
    setErrMsg(false);
    setSuccessMsg(false);
  };

  const successDialog = async () => {
    resetErrMsg();
    AlertSetter(setSuccessMsg, 'Successfully updated!');
  };

  const failureDialog = async () => {
    resetErrMsg();
    AlertSetter(
      setErrMsg,
      'Oops! Something went wrong! Make sure you have entered all the details correctly!'
    );
  };

  const saveBtnPressed = async (e) => {
    if (!billFormat.selectedMachine) {
      return;
    }
    const data = {
      machine_number: billFormat.selectedMachine.machine_number,
      data: [
        { key: 0, text: billFormat.shopName },
        { key: 4, text: billFormat.address1 },
        { key: 5, text: billFormat.address2 },
        { key: 6, text: billFormat.address3 },
        { key: 7, text: billFormat.address4 },
        { key: 8, text: billFormat.telNo },
        { key: 9, text: billFormat.gstNumber },
        { key: 10, text: billFormat.footer1 },
        { key: 11, text: billFormat.footer2 },
      ],
    };
    try {
      await BillFormatService.updateBillFormat(data);
      successDialog();
    } catch (error) {
      failureDialog();
    }
  };

  useEffect(() => {
    getMachines();
    // eslint-disable-next-line
  }, []);

  const loadBillFormat = async (currentState, selectedMachine) => {
    if (!selectedMachine) {
      return;
    }
    const data = await BillFormatService.getBillFormat(
      selectedMachine.machine_number
    );
    setBillFormat({
      ...currentState,
      shopName: (data.find((d) => d.key === 0) || {}).text,
      address1: (data.find((d) => d.key === 4) || {}).text,
      address2: (data.find((d) => d.key === 5) || {}).text,
      address3: (data.find((d) => d.key === 6) || {}).text,
      address4: (data.find((d) => d.key === 7) || {}).text,
      telNo: (data.find((d) => d.key === 8) || {}).text,
      gstNumber: (data.find((d) => d.key === 9) || {}).text,
      footer1: (data.find((d) => d.key === 10) || {}).text,
      footer2: (data.find((d) => d.key === 11) || {}).text,
    });
  };

  const getMachines = async (e) => {
    setLoadingIndicator(true);
    const machines = await BillFormatService.getMachines();
    const updateMachines = {
      ...billFormat,
      machines,
    };
    setBillFormat(updateMachines);
    setLoadingIndicator(false);
    if (!billFormat.selectedMachine && machines.length) {
      const updateSelectedMachine = {
        ...updateMachines,
        selectedMachine: machines[0],
      };
      setBillFormat(updateSelectedMachine);
      await loadBillFormat(updateSelectedMachine, machines[0]);
    }
  };

  return (
    <>
      {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC && (
        <RestrictionInfo
          title={'Feature not available '}
          content={'To get bill format options upgrade subscription to premium'}
        />
      )}
      <div className={styles.contentLayout}>
        {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
          <>
            <Loader isOpen={loadingIndicator} />
            <div className={styles.Bill}>
              <div className={styles.machineDiv}>
                <label className={styles.machineSelectTitle}>
                Counter Name
                </label>
                <TextField
                  style={{ marginTop: '15px', marginBottom: '15px' }}
                  select
                  name="selectedMachine"
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  value={billFormat.selectedMachine}
                  onChange={(event) => {
                    const selectedMachine = event.target.value;
                    handleBillFormatChange(event);
                    loadBillFormat(selectedMachine);
                  }}
                >
                  {billFormat.machines?.map((machine) => (
                    <MenuItem key={machine.id} value={machine}>
                      {MachineHelper.getMachineString(machine)}
                    </MenuItem>
                  ))}
                </TextField>
                {errMsg && <Error title={errMsg} />}
                {successMsg && <Success title={successMsg} />}
              </div>
              <div className={styles.BillHeaderDiv}>
                <span className={styles.pageHead}>Bill header details</span>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Shop Name</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="shopName"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={handleBillFormatChange}
                      value={billFormat.shopName}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Address 1</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="address1"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={handleBillFormatChange}
                      value={billFormat.address1}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Address 2</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="address2"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={handleBillFormatChange}
                      value={billFormat.address2}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Address 3</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="address3"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={handleBillFormatChange}
                      value={billFormat.address3}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Address 4</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="address4"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={handleBillFormatChange}
                      value={billFormat.address4}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Tel No</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="telNo"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={handleBillFormatChange}
                      value={billFormat.telNo}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Tax Number</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="gstNumber"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={handleBillFormatChange}
                      value={billFormat.gstNumber}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Footer 1</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="footer1"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={handleBillFormatChange}
                      value={billFormat.footer1}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldTitleDiv}>
                    <span className={styles.fieldTitle}>Footer 2</span>
                  </div>
                  <div className={styles.fieldContent}>
                    <TextField
                      name="footer2"
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={handleBillFormatChange}
                      value={billFormat.footer2}
                    />
                  </div>
                </Grid>
                {userHasShopWritePermission && (
                  <Grid item xs={12}>
                    <div className={styles.buttonArea}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={saveBtnPressed}
                        style={{ backgroundColor: '#00a65a' }}
                        disabled={loadingIndicator}
                      >
                        Update bill format
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
            <div className={styles.Bill}>
              <div className={styles.billPreview}>
                <div className={styles.billSample}>
                  <div style={{ textAlign: 'center' }}>
                    <h3>{billFormat.shopName}</h3>
                    <h6>{billFormat.address1}</h6>
                    <h6>{billFormat.address2}</h6>
                    <h6>{billFormat.address3}</h6>
                    <h6>{billFormat.address4}</h6>
                    <h6>{billFormat.telNo}</h6>
                    <h6>{billFormat.gstNumber}</h6>
                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                      CASH/BILL
                    </span>
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: 12,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={styles.borderBottomDotted}
                    >
                      <span>NO 00011012</span>
                      <span>22-02-2016</span>
                    </div>
                    <div
                      style={{ fontSize: 12, display: 'flex', padding: 2 }}
                      className={styles.borderBottomDotted}
                    >
                      <span style={{ width: '40%' }}>DESCRIPTION</span>
                      <span style={{ width: '20%' }}>QTY</span>
                      <span style={{ width: '20%' }}>RATE</span>
                      <span style={{ width: '20%', textAlign: 'right' }}>
                        AMOUNT
                      </span>
                    </div>
                    <div style={{ fontSize: 12, display: 'flex', padding: 2 }}>
                      <span style={{ width: '40%' }}>Amul</span>
                      <span style={{ width: '20%' }}>2.00</span>
                      <span style={{ width: '20%' }}>5.00</span>
                      <span style={{ width: '20%', textAlign: 'right' }}>
                        10.00
                      </span>
                    </div>
                    <div style={{ fontSize: 12, display: 'flex', padding: 2 }}>
                      <span style={{ width: '40%' }}>Tea</span>
                      <span style={{ width: '20%' }}>4.00</span>
                      <span style={{ width: '20%' }}>15.00</span>
                      <span style={{ width: '20%', textAlign: 'right' }}>
                        60.00
                      </span>
                    </div>
                    <div style={{ fontSize: 12, display: 'flex', padding: 2 }}>
                      <span style={{ width: '40%' }}>Black Forest</span>
                      <span style={{ width: '20%' }}>5.00</span>
                      <span style={{ width: '20%' }}>8.00</span>
                      <span style={{ width: '20%', textAlign: 'right' }}>
                        40.00
                      </span>
                    </div>
                    <div style={{ fontSize: 12, display: 'flex', padding: 2 }}>
                      <span style={{ width: '40%' }}>Kaju Draksha</span>
                      <span style={{ width: '20%' }}>7.00</span>
                      <span style={{ width: '20%' }}>60.00</span>
                      <span style={{ width: '20%', textAlign: 'right' }}>
                        420.00
                      </span>
                    </div>

                    <div
                      style={{
                        fontSize: 14,
                        display: 'flex',
                        padding: 2,
                        fontWeight: 'bold',
                      }}
                      className={styles.borderTOpDotted}
                    >
                      <span style={{ width: '50%' }}>CASH</span>
                      <span style={{ width: '50%', textAlign: 'right' }}>
                        530.00
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        padding: 2,
                        textAlign: 'center',
                      }}
                    >
                      <span style={{ width: '100%' }}>
                        {billFormat.footer1}
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        padding: 2,
                        textAlign: 'center',
                      }}
                    >
                      <span style={{ width: '100%' }}>
                        {billFormat.footer2}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
